import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles, CircularProgress } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  },
  loadingProgress: {
    margin: 'auto',
    display: 'flex',
    height: '90% !important',
  },
  loadingcontainer: {
    flex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#00000063',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2
  },
  loadingindicator: {
    flexDirection: 'row',
    justifyContent: "center",
    alignItems: 'center',
    height: '100%',
  }
}));

const DashboardLayout = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { loading } = useSelector(state => state.authUser)

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {loading &&
              <div className={classes.loadingcontainer}>
                <div className={classes.loadingindicator}>
                  <CircularProgress className={classes.loadingProgress} />
                </div>
              </div>
            }
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
