import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Input,
  Avatar,
  makeStyles
} from '@material-ui/core';

import EditIcon from "@material-ui/icons/EditOutlined";
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import EditLocationIcon from "@material-ui/icons/EditLocationOutlined";
import UploadIcon from "@material-ui/icons/CloudUploadOutlined";

import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { getDatabase, remove, set, ref as dbRef, onValue, push, child, update } from "firebase/database";
import { getStorage, ref as storageRef, uploadString, getDownloadURL, deleteObject } from "firebase/storage";
import { useDispatch } from 'react-redux';

import { setLoading } from '../../redux/actions';
import ConfirmActionModal from '../../components/confirm_action_modal';
import { NotificationManager } from '../../components/react-notifications';
import MapModal from '../../components/gmap';
import ExpandableTableRow from './ExpandableTableRow';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
    width: 200,
    height: 200
  },
  avatar_edit: {
    width: 290,
    display: 'flex',
    alignItems: 'center'
  },
  avatar_btns: {
    height: 50
  },
  editLocation: {
    marginLeft: theme.spacing(2)
  },
  locationDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  datePicker: {
    width: 250
  }
}));

const CustomTableCell = ({ row, name, onChange, showGMap }) => {
  const classes = useStyles();
  const { isEditMode } = row;

  const start = row['start'] === undefined ? '' : new Date(row['start'] * 1000);
  const end = row['end'] === undefined ? '' : new Date(row['end'] * 1000);

  const [selectedStartDate, setSelectedStartDate] = useState(new Date(row['start'] * 1000));
  const [selectedEndDate, setSelectedEndDate] = useState(new Date(row['end'] * 1000));

  const onEditLocation = () => {
    showGMap(row.id, row.location.lat, row.location.lon);
  };

  const changedStartDate = (event) => {
    console.log("-----------------", event);
    const d = new Date(event);
    const e = { target: { name: 'start', value: d.getTime() / 1000 } };
    onChange(e, row);
  }

  const changedEndDate = (event) => {
    console.log("-----------------", event);
    const d = new Date(event);
    const e = { target: { name: 'end', value: d.getTime() / 1000 } };
    onChange(e, row);
  }

  const handleFileInput = useRef(null);

  const onUploadBtnClick = () => {
    handleFileInput.current.click();
  };

  const onUploadProfile = (event) => {
    let files = event.target.files[0];
    var reader = new FileReader();
    reader.onload = (e) => {
      var img = document.createElement("img");
      img.onload = () => {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var MAX_WIDTH = 2000;
        var MAX_HEIGHT = 2000;
        var width = img.width;
        var height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        var dataurl = canvas.toDataURL('image/webp', '0.85');

        const d = new Date();
        let fileCreateTime = d.getTime().toString();
        const storage = getStorage();
        const sref = storageRef(storage, `timetable/${fileCreateTime}`);

        uploadString(sref, dataurl, 'data_url').then((snapshot) => {
          console.log('Uploaded a data_url string!');
          getDownloadURL(snapshot.ref).then((downloadURL) => {
            console.log('File available at', downloadURL);

            const res = { target: { name: 'image', value: downloadURL } };
            onChange(res, row);
          });

        });
      }
      img.src = e.target.result;
    }
    reader.readAsDataURL(files);
    // const d = new Date();
    // let fileCreateTime = d.getTime().toString();
    // const storage = getStorage();
    // const sref = storageRef(storage, `timetable/${fileCreateTime}`);
    // const uploadTask = uploadBytesResumable(sref, files);

    // uploadTask.on('state_changed',
    //   (snapShot) => {
    //     //takes a snap shot of the process as it is happening
    //     const progress = (snapShot.bytesTransferred / snapShot.totalBytes) * 100;
    //     console.log('Upload is ' + progress.toFixed(2) + '% done');
    //     switch (snapShot.state) {
    //       case 'paused':
    //         console.log('Upload is paused');
    //         break;
    //       case 'running':
    //         console.log('Upload is running');
    //         break;
    //       default:
    //         console.log('Upload is running');
    //     }
    //   }, (err) => {
    //     //catches the errors
    //     console.log(err)
    //   }, () => {
    //     getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
    //       console.log('File available at', downloadURL);
    //       const res = { target: { name: 'image', value: downloadURL } };
    //       onChange(res, row);
    //     });
    //   })
  };

  const onCancelProfile = () => {
    const storage = getStorage();

    // Create a reference to the file to delete
    const desertRef = storageRef(storage, row['image']);

    // Delete the file
    deleteObject(desertRef).then(() => {
      // File deleted successfully
      console.log("file deleted success");
    }).catch((error) => {
      // Uh-oh, an error occurred!
      console.log("delete ---", error);
    });

    const e = { target: { name: 'image', value: '' } };
    onChange(e, row);
  };

  return (
    <TableCell align="left">
      {(name === 'lat') && (row['location'] === undefined ? '' : row['location']['lat'])}

      {(name === 'lon') && (isEditMode ? (
        <div className={classes.locationDiv}>
          {row['location'] === undefined ? '' : row['location']['lon']}
          <IconButton
            aria-label="done"
            className={classes.editLocation}
            onClick={() => onEditLocation()}
          >
            <EditLocationIcon />
          </IconButton>
        </div>
      ) : (
        row['location'] === undefined ? '' : row['location']['lon']
      ))}

      {name === 'image' && (isEditMode ? (
        <div className={classes.avatar_edit}>
          <Avatar
            className={classes.avatar}
            src={row[name]}
            variant="rounded"
          />
          <IconButton
            aria-label="done"
            className={classes.avatar_btns}
            onClick={() => onUploadBtnClick()}
          >
            <UploadIcon />
          </IconButton>
          <input
            type="file"
            ref={handleFileInput}
            name="upload_file"
            style={{ display: 'none' }}
            onChange={onUploadProfile}
          />
          <IconButton
            aria-label="revert"
            className={classes.avatar_btns}
            onClick={() => onCancelProfile()}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ) : (
        <Avatar
          className={classes.avatar}
          src={row[name]}
          variant="rounded"
        />
      ))}

      {(name === 'start') && (isEditMode ? (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            value={selectedStartDate}
            onChange={(e) => { setSelectedStartDate(e); changedStartDate(e); }}
            label=""
            ampm={false}
            onError={console.log}
            minDate={new Date("2022-01-01T00:00")}
            format="dd.MM.yyyy HH:mm"
            className={classes.datePicker}
          />
        </MuiPickersUtilsProvider>
      ) : (
        row['start'] === undefined ? '' : (start.getDate() + '.' + (start.getMonth() + 1) + '.' + start.getFullYear() + ' ' + ("0" + start.getHours()).slice(-2) + ":" + ("0" + start.getMinutes()).slice(-2))
      ))}

      {(name === 'end') && (isEditMode ? (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            value={selectedEndDate}
            onChange={(e) => { setSelectedEndDate(e); changedEndDate(e); }}
            label=""
            onError={console.log}
            ampm={false}
            minDate={new Date("2022-01-01T00:00")}
            format="dd.MM.yyyy HH:mm"
            className={classes.datePicker}
          />
        </MuiPickersUtilsProvider>
      ) : (
        row['end'] === undefined ? '' : (end.getDate() + '.' + (end.getMonth() + 1) + '.' + end.getFullYear() + ' ' + ("0" + end.getHours()).slice(-2) + ":" + ("0" + end.getMinutes()).slice(-2))
      ))}

      {(name !== 'lat' && name !== 'lon' && name !== 'image' && name !== 'end' && name !== 'start') && (isEditMode ? (
        <Input
          value={row[name] === undefined ? '' : row[name]}
          name={name}
          onChange={e => onChange(e, row)}
          className={classes.input}
        />
      ) : (
        row[name]
      ))}

    </TableCell>
  );
};

const Results = ({ className, ...rest }, ref) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [previous, setPrevious] = useState({});
  const [isShowConfirm, toggleConfirm] = useState(false);
  const [delId, setDelId] = useState('');
  const [isShowMap, setIsShowMap] = useState(false);
  const [selId, setSelId] = useState('');
  const [curLat, setCurLat] = useState(0);
  const [curLon, setCurLon] = useState(0);
  const dispatch = useDispatch();

  const db = getDatabase();

  useEffect(() => {
    let arrTmp = [];

    const onMigrate = (id, row) => {  
      set(dbRef(db, 'timetable/' + id), row)
        .then(() => {
          // Data saved successfully!
          console.log("+++++++++ Migrate success");
        })
        .catch((error) => {
          // The write failed...
          console.log("----------- Migrate ", error);
        });
    };

    const storage = getStorage();

    dispatch(setLoading(true));

    navigator.geolocation.getCurrentPosition(function (position) {
      console.log(position);
      setCurLat(position.coords.latitude);
      setCurLon(position.coords.longitude);
    });

    return onValue(dbRef(db, '/timetable'), (snapshot) => {
      snapshot.forEach(function (childSnapshot) {
        var childKey = childSnapshot.key;
        var childData = childSnapshot.val();
        childData['id'] = childKey;
        childData['isEditMode'] = false;

        var arrPrograms = [];

        if (childData['programm'] !== undefined) {
          if (typeof childData['programm'] === 'object' && !Array.isArray(childData['programm']) && childData['programm'] !== null) {
            const keys = Object.keys(childData['programm']);
            keys.forEach((key) => {
              var data = childData['programm'][key];
              data['id'] = key;
              data['isEditMode'] = false;

              arrPrograms.push(data);
            })
          } else if (Array.isArray(childData['programm'])) {
            childData['programm'].forEach(function (data, index) {
              data['id'] = index;
              data['isEditMode'] = false;

              arrPrograms.push(data);
            })
          }
        }

        childData['programm'] = arrPrograms;

        arrTmp.push(childData);
      });
      
      let arrRows = [];
      const promises = arrTmp.reduce((rowPromise, row) => rowPromise.then(async () => {
        const d = new Date();
        const fileName = d.getTime();
        const sref = storageRef(storage, `timetable/${fileName}`);

        const rowTmp = {};
        const keys = Object.keys(row);
        keys.forEach((key) => {
          rowTmp[key] = row[key];
        })

        var imgStr = rowTmp['image'] === undefined ? '' : rowTmp['image'];
        console.log("111111");

        if (imgStr.includes('http://') || imgStr.includes('https://')) {
          arrRows.push(rowTmp);
        } else if (imgStr !== '') {
          await uploadString(sref, 'data:image/png;base64, ' + imgStr, 'data_url').then(async (snapshot) => {
            console.log('Uploaded a data_url string!');
            await getDownloadURL(snapshot.ref).then((downloadURL) => {
              console.log('File available at', downloadURL);

              rowTmp['image'] = downloadURL;

              onMigrate(rowTmp['id'], rowTmp);

              arrRows.push(rowTmp);
            });

          });
        } else {
          console.log("image string null = ", imgStr);
          arrRows.push(rowTmp);
        }
      }), Promise.resolve());

      promises.then(() => {
        console.log("result --- ", arrRows);
        setRows(arrRows);

        dispatch(setLoading(false));
      });

    }, {
      onlyOnce: true
    });
  }, [db, dispatch]);

  const onChange = (e, row) => {
    console.log(e);
    if (!previous[row.id]) {
      setPrevious(state => ({ ...state, [row.id]: row }));
    }
    const value = e.target.value;
    const name = e.target.name;
    const { id } = row;
    const newRows = rows.map(row => {
      if (row.id === id) {
        if (name === 'lat_lon') {
          return { ...row, location: { lat: e.target.lat, lon: e.target.lon } };
        } else {
          return { ...row, [name]: value };
        }
      }
      return row;
    });
    setRows(newRows);
  };

  const onToggleEditMode = id => {
    setRows(state => {
      return state.map(row => {
        if (row.id === id) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };

  const onSave = (id, row) => {
    dispatch(setLoading(true));

    set(dbRef(db, 'timetable/' + id), row)
      .then(() => {
        // Data saved successfully!
        console.log("+++++++++ update success");
        setPrevious(state => {
          delete state[id];
          return state;
        });

        onToggleEditMode(id);

        dispatch(setLoading(false));

        NotificationManager.success(
          'Erfolgreich geändert',
          "Alert",
          3000,
          null,
          null,
          ''
        );
      })
      .catch((error) => {
        // The write failed...
        console.log("----------- update ", error);
        dispatch(setLoading(false));

        NotificationManager.warning(
          'Änderung fehlgeschlagen',
          "Alert",
          3000,
          null,
          null,
          ''
        );
      });
  };

  const onRevert = id => {
    const newRows = rows.map(row => {
      if (row.id === id) {
        return previous[id] ? previous[id] : row;
      }
      return row;
    });
    setRows(newRows);
    setPrevious(state => {
      delete state[id];
      return state;
    });
    onToggleEditMode(id);
  };

  const onRemoveClick = () => {
    onDelete(delId);
  };

  const onDelete = id => {
    dispatch(setLoading(true));

    remove(dbRef(db, 'timetable/' + id))
      .then(() => {
        console.log("----------- delete success");
        const newRows = rows.filter(row => row.id !== id);
        setRows(newRows);
        dispatch(setLoading(false));

        NotificationManager.success(
          'Erfolgreich gelöscht',
          "Alert",
          3000,
          null,
          null,
          ''
        );
      })
      .catch(error => {
        console.log("------------ delete ", error);
        dispatch(setLoading(false));
        NotificationManager.warning(
          'Löschen fehlgeschlagen',
          "Alert",
          3000,
          null,
          null,
          ''
        );
      });
  }

  useImperativeHandle(ref, () => ({
    onAdd() {
      dispatch(setLoading(true));

      const db = getDatabase();
      // Get a key for a new Post.
      const newPostKey = push(child(dbRef(db), 'timetable/')).key;

      const date = new Date();

      const postData = {
        id: newPostKey,
        isEditMode: true,
        location: {
          lat: curLat,
          lon: curLon
        },
        start: date.getTime() / 1000,
        end: date.getTime() / 1000
      };

      // Write the new post's data simultaneously in the posts list and the user's post list.
      const updates = {};
      updates['/timetable/' + newPostKey] = postData;

      update(dbRef(db), updates)
        .then(() => {
          console.log("++++++++ add success");
          let newRows = rows.map(row => row);
          console.log(newRows);
          newRows.unshift(postData);
          console.log(newRows);
          setRows(newRows);
          setPage(0);

          dispatch(setLoading(false));

          NotificationManager.success(
            'Erfolgreich erstellt',
            "Alert",
            3000,
            null,
            null,
            ''
          );
        })
        .catch((error) => {
          console.log("+++++  add ", error);
          dispatch(setLoading(false));
          NotificationManager.warning(
            'Hinzufügen fehlgeschlagen',
            "Alert",
            3000,
            null,
            null,
            ''
          );
        });
    }
  }));

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const showGMap = (id, lat, lon) => {
    setSelId(id);
    setCurLat(lat);
    setCurLon(lon);

    setIsShowMap(true);
  };

  const changedPosition = (lat, lon) => {
    console.log("-----------", lat, lon)
    rows.forEach(row => {
      if (row.id === selId) {
        const e = { target: { name: 'lat_lon', value: lat, lat: lat, lon: lon } };
        onChange(e, row);
      }
    });
  };

  const onSaveProgram = (programs, id, row) => {
    const e = { target: { name: 'programm', value: programs } };
    onChange(e, row);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <ConfirmActionModal
        title="Löschen bestätigen"
        text="Möchtest du diesen Eintrag wirklich löschen"
        onConfirm={() => { onRemoveClick(); toggleConfirm(false); }}
        show={isShowConfirm}
        onClose={() => toggleConfirm(false)}
        confirmText="Löschen"
      />
      <MapModal
        isMarkerShown={true}
        curPos={{ lat: curLat, lon: curLon }}
        title="Position wählen"
        onConfirm={(lat, lon) => { changedPosition(lat, lon); setIsShowMap(false); }}
        show={isShowMap}
        onClose={() => setIsShowMap(false)}
        confirmText="Ok"
      />
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell rowSpan={2} padding="checkbox" />
                <TableCell rowSpan={2} align="left" />
                <TableCell rowSpan={2}>Bild</TableCell>
                <TableCell rowSpan={2}>Name</TableCell>
                <TableCell rowSpan={2}>Start</TableCell>
                <TableCell rowSpan={2}>Ende</TableCell>
                <TableCell rowSpan={2}>Info</TableCell>
                <TableCell colSpan={2} align="center">Standort</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Breitengrad</TableCell>
                <TableCell>Längengrad</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(page * limit, (page + 1) * limit).map((row) => (
                <ExpandableTableRow
                  hover
                  key={row.id}
                  programs={row['programm'] === undefined ? [] : row['programm']}
                  timeKey={row.id}
                  onSaveProgram={(programs) => onSaveProgram(programs, row.id, row)}
                >
                  <TableCell className={classes.selectTableCell}>
                    {row.isEditMode ? (
                      <>
                        <IconButton
                          aria-label="done"
                          onClick={() => onSave(row.id, row)}
                        >
                          <DoneIcon />
                        </IconButton>
                        <IconButton
                          aria-label="revert"
                          onClick={() => onRevert(row.id)}
                        >
                          <RevertIcon />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <IconButton
                          aria-label="edit"
                          onClick={() => onToggleEditMode(row.id)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          onClick={() => { toggleConfirm(true); setDelId(row.id); }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                  <CustomTableCell {...{ row, name: "image", onChange }} showGMap={(id, lat, lon) => showGMap(id, lat, lon)} />
                  <CustomTableCell {...{ row, name: "name", onChange }} showGMap={(id, lat, lon) => showGMap(id, lat, lon)} />
                  <CustomTableCell {...{ row, name: "start", onChange }} showGMap={(id, lat, lon) => showGMap(id, lat, lon)} />
                  <CustomTableCell {...{ row, name: "end", onChange }} showGMap={(id, lat, lon) => showGMap(id, lat, lon)} />
                  <CustomTableCell {...{ row, name: "info", onChange }} showGMap={(id, lat, lon) => showGMap(id, lat, lon)} />
                  <CustomTableCell {...{ row, name: "lat", onChange }} showGMap={(id, lat, lon) => showGMap(id, lat, lon)} />
                  <CustomTableCell {...{ row, name: "lon", onChange }} showGMap={(id, lat, lon) => showGMap(id, lat, lon)} />
                </ExpandableTableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={rows.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25, 100]}
      />
    </Card>
  );
};

export default forwardRef(Results);
