import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Checkbox,
  IconButton,
  makeStyles
} from '@material-ui/core';

import EditIcon from "@material-ui/icons/EditOutlined";
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import EditLocationIcon from "@material-ui/icons/EditLocationOutlined";

import {
  getDatabase,
  remove,
  set,
  ref as dbRef,
  onValue,
  push,
  child,
  update
} from "firebase/database";
import { useDispatch } from 'react-redux';

import { setLoading } from '../../redux/actions';
import ConfirmActionModal from '../../components/confirm_action_modal';
import { NotificationManager } from '../../components/react-notifications';
import MapModal from '../../components/gmap';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: { marginRight: theme.spacing(2) },
  editLocation: { marginLeft: theme.spacing(2) }
}));

const CustomTableCell = ({ row, name, onChange, showGMap }) => {
  const classes = useStyles();
  const { isEditMode, lat, lon } = row;

  const handleCheckbox = (name) => (
    isEditMode ? (
      <Checkbox
        name={name}
        checked={row[name] === undefined ? false : row[name]}
        onChange={(event) => onChange(event, row)}
        value='true'
      />
    ) : (
      <Checkbox
        disabled
        checked={row[name] === undefined ? false : row[name]}
      />
    )
  );

  return (
    <TableCell align="left">
      {name === 'menonly' && handleCheckbox('menonly')}
      {name === 'wheelchair' && handleCheckbox('wheelchair')}
      {name === 'lat' && lat}
      {name === 'lon' && (
        isEditMode ? (
          <>
            {lon}
            <IconButton
              aria-label="done"
              className={classes.editLocation}
              onClick={() => showGMap(row.id, row.lat, row.lon)}
            >
              <EditLocationIcon />
            </IconButton>
          </>
        ) : lon
      )}
    </TableCell>
  );
};

const Results = ({ className, ...rest }, ref) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [previous, setPrevious] = useState({});
  const [isShowConfirm, toggleConfirm] = useState(false);
  const [delId, setDelId] = useState('');
  const [isShowMap, setIsShowMap] = useState(false);
  const [selId, setSelId] = useState('');
  const [curLat, setCurLat] = useState(0);
  const [curLon, setCurLon] = useState(0);
  const dispatch = useDispatch();

  const db = getDatabase();

  useEffect(() => {
    let arrTmp = [];
    dispatch(setLoading(true));

    navigator.geolocation.getCurrentPosition(function(position) {
      console.log(position);
      setCurLat(position.coords.latitude);
      setCurLon(position.coords.longitude);
    });

    return onValue(dbRef(db, '/maplocations/toiletten'), (snapshot) => {
      snapshot.forEach(function (childSnapshot) {
        var childKey = childSnapshot.key;
        var childData = childSnapshot.val();
        childData['id'] = childKey;
        childData['isEditMode'] = false;

        arrTmp.push(childData);
      });
      setRows(arrTmp);

      dispatch(setLoading(false));
    }, {
      onlyOnce: true
    });
  }, [db, dispatch]);

  const onChange = (e, row) => {
    if (!previous[row.id]) {
      setPrevious(state => ({ ...state, [row.id]: row }));
    }
    const value = e.target.value;
    const name = e.target.name;
    const { id } = row;

    const updateRow = (name, value) => {
      const updatedRow = { ...row, [name]: name === 'lat_lon' ? value : (row[name] !== undefined ? !row[name] : true) };
      return updatedRow;
    };

    const newRows = rows.map(r => (r.id === id ? updateRow(name, value) : r));
    setRows(newRows);
  };

  const toggleEditMode = (id) => {
    const updatedRows = rows.map(row => (row.id === id ? { ...row, isEditMode: !row.isEditMode } : row));
    setRows(updatedRows);
  };

  const saveChanges = (id, row) => {
    dispatch(setLoading(true));

    set(dbRef(db, 'maplocations/toiletten/' + id), row)
      .then(() => {
        setPrevious(state => {
          delete state[id];
          return state;
        });

        toggleEditMode(id);

        dispatch(setLoading(false));

        NotificationManager.success(
          'Erfolgreich geändert',
          "Alert",
          3000,
          null,
          null,
          ''
        );
      })
      .catch((error) => {
        dispatch(setLoading(false));
        NotificationManager.warning(
          'Änderung fehlgeschlagen',
          "Alert",
          3000,
          null,
          null,
          ''
        );
      });
  };

  const revertChanges = (id) => {
    const newRows = rows.map(row => (row.id === id ? (previous[id] || row) : row));
    setRows(newRows);

    setPrevious(state => {
      delete state[id];
      return state;
    });

    toggleEditMode(id);
  };

  const onRemoveClick = () => {
    onDelete(delId);
  };

  const onDelete = (id) => {
    dispatch(setLoading(true));

    remove(dbRef(db, 'maplocations/toiletten/' + id))
      .then(() => {
        const newRows = rows.filter(row => row.id !== id);
        setRows(newRows);
        dispatch(setLoading(false));

        NotificationManager.success(
          'Erfolgreich gelöscht',
          "Alert",
          3000,
          null,
          null,
          ''
        );
      })
      .catch(error => {
        dispatch(setLoading(false));
        NotificationManager.warning(
          'Löschen fehlgeschlagen',
          "Alert",
          3000,
          null,
          null,
          ''
        );
      });
  };

  useImperativeHandle(ref, () => ({
    onAdd() {
      dispatch(setLoading(true));

      const db = getDatabase();
      const newPostKey = push(child(dbRef(db), 'maplocations/toiletten/')).key;

      const postData = {
        id: newPostKey,
        isEditMode: true,
        lat: curLat,
        lon: curLon
      };

      const updates = {};
      updates['/maplocations/toiletten/' + newPostKey] = postData;

      update(dbRef(db), updates)
        .then(() => {
          let newRows = [...rows];
          newRows.unshift(postData);
          setRows(newRows);
          setPage(0);

          dispatch(setLoading(false));

          NotificationManager.success(
            'Erfolgreich hinzugefügt',
            "Alert",
            3000,
            null,
            null,
            ''
          );
        })
        .catch((error) => {
          dispatch(setLoading(false));
          NotificationManager.warning(
            'Hinzufügen fehlgeschlagen',
            "Alert",
            3000,
            null,
            null,
            ''
          );
        });
    }
  }));

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const showGMap = (id, lat, lon) => {
    setSelId(id);
    setCurLat(lat);
    setCurLon(lon);
    setIsShowMap(true);
  };

  const changedPosition = (lat, lon) => {
    rows.forEach(row => {
      if (row.id === selId) {
        const e = { target: { name: 'lat_lon', value: lat, lat: lat, lon: lon } };
        onChange(e, row);
      }
    });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <ConfirmActionModal
        title="Löschen bestätigen"
        text="Möchtest du diesen Eintrag wirklich löschen"
        onConfirm={() => { onRemoveClick(); toggleConfirm(false); }}
        show={isShowConfirm}
        onClose={() => toggleConfirm(false)}
        confirmText="Löschen"
      />
      <MapModal
        isMarkerShown={true}
        curPos={{ lat: curLat, lon: curLon }}
        title="Position wählen"
        onConfirm={(lat, lon) => { changedPosition(lat, lon); setIsShowMap(false); }}
        show={isShowMap}
        onClose={() => setIsShowMap(false)}
        confirmText="Ok"
      />
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left" />
                <TableCell>Breitengrad</TableCell>
                <TableCell>Längengrad</TableCell>
                <TableCell>Nur Pissoir</TableCell>
                <TableCell>Behindertengerecht</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(page * limit, (page + 1) * limit).map((row) => (
                <TableRow hover key={row.id}>
                  <TableCell className={classes.selectTableCell}>
                    {row.isEditMode ? (
                      <>
                        <IconButton aria-label="done" onClick={() => saveChanges(row.id, row)}>
                          <DoneIcon />
                        </IconButton>
                        <IconButton aria-label="revert" onClick={() => revertChanges(row.id)}>
                          <RevertIcon />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <IconButton aria-label="edit" onClick={() => toggleEditMode(row.id)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton aria-label="delete" onClick={() => { toggleConfirm(true); setDelId(row.id); }}>
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                  <CustomTableCell {...{ row, name: "lat", onChange }} showGMap={showGMap} />
                  <CustomTableCell {...{ row, name: "lon", onChange }} showGMap={showGMap} />
                  <CustomTableCell {...{ row, name: "menonly", onChange }} showGMap={showGMap} />
                  <CustomTableCell {...{ row, name: "wheelchair", onChange }} showGMap={showGMap} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={rows.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25, 100]}
      />
    </Card>
  );
};

export default forwardRef(Results);
