// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth } from 'firebase/auth';

const { initializeAppCheck, ReCaptchaV3Provider } = require('firebase/app-check');

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDgzGzvWjouxZIsrwcob0X1ZqYzIOaKZMY',
  authDomain: 'raeppliapp.firebaseapp.com',
  databaseURL: 'https://raeppliapp-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'raeppliapp',
  storageBucket: 'raeppliapp.appspot.com',
  messagingSenderId: '476936345574',
  appId: '1:476936345574:web:fbc1101a7155922a29e827',
  measurementId: 'G-N61YYV05L6'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Lfpf34dAAAAADYDzgwglYgl4dUXd9I48zs76AU9'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

const auth = getAuth(app);

export {
  auth
};
