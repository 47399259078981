import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  Hidden,
  List,
  makeStyles
} from '@material-ui/core';
import {
  Award,
  Calendar, DivideSquare,
  Instagram,
  MapPin,
  Users
} from 'react-feather';
import NavItem from './NavItem';

// const user = {
//   avatar: '/static/images/avatars/avatar_6.png',
//   jobTitle: 'Senior Developer',
//   name: 'Katarina Smith'
// };

const items = [
  {
    href: '/app/dashboard',
    icon: DivideSquare,
    title: 'Dashboard'
  },
  {
    href: '/app/visitors',
    icon: Users,
    title: 'Nutzer'
  },
  {
    href: '/app/formations',
    icon: Award,
    title: 'Formationen'
  },
  {
    href: '/app/socialmedia',
    icon: Instagram,
    title: 'Social Media'
  },
  {
    href: '',
    icon: MapPin,
    title: 'Karteneinträge',
    child: [
      {
        href: '/app/toiletten',
        icon: MapPin,
        title: 'Toiletten'
      },
      {
        href: '/app/schnitzelbangg',
        icon: MapPin,
        title: 'Schnitzelbängg'
      },
      {
        href: '/app/pfeiferundtrommelschulen',
        icon: MapPin,
        title: 'Pfeifer & Trommelschulen'
      },
      {
        href: '/app/cliquekaeller',
        icon: MapPin,
        title: 'Cliquekäller'
      }
    ]
  },
  {
    href: '/app/timetable',
    icon: Calendar,
    title: 'Programm'
  }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 270
  },
  desktopDrawer: {
    width: 270,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const [isShowSub, setIsShowSub] = useState(false);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);


  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box p={2} key={1}>
        <List>
          {items.map((item) =>
            item.href === '' ? (
              <div key={item.title}>
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                  type={1}
                  isShow={isShowSub}
                  onDropHandle={() => {setIsShowSub(state => !state);}}
                />
                {isShowSub && item.child.map((itemchild) =>
                  <NavItem
                    href={itemchild.href}
                    key={itemchild.title}
                    title={itemchild.title}
                    icon={itemchild.icon}
                    type={2}
                    isShow={isShowSub}
                  />
                )}
              </div>) : (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
                type={1}
                isShow={isShowSub}
                onCloseDrop={() => {setIsShowSub(false);}}
              />
            )
          )}
        </List>
      </Box>
      <Box flexGrow={1} key={2} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default NavBar;
