import { ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import React from 'react';
import { ModalText } from './modal';
import { ModalWindow } from './styles';

const ConfirmActionModal = (props) => {
  const { show, onConfirm, onClose, title, text, content, itemName, confirmText } = props;

  return (
    <ModalWindow isOpen={show} toggle={onClose} centered>
      <ModalHeader toggle={onClose}>
        {title}
      </ModalHeader>
      <ModalBody>
        <ModalText>
          {text}
          <strong>{` ${itemName}`}</strong>
          ?
          {content}
        </ModalText>
      </ModalBody>
      <ModalFooter>
        <Button color="white" onClick={onClose}>
          Cancel
        </Button>
        <Button color="danger" onClick={onConfirm}>
          {confirmText}
        </Button>
      </ModalFooter>
    </ModalWindow>
  );
};

ConfirmActionModal.defaultProps = {
  content: null,
  itemName: '',
  confirmText: 'Bestätigen'
};

export default ConfirmActionModal;
