import React, { useState } from 'react';
import { ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { ModalWindow } from '../confirm_action_modal/styles';
import MapComponent from './MapComponent';

const MapModal = (props) => {
  const { show, onConfirm, onClose, title, confirmText, isMarkerShown, curPos } = props;
  const [lat, setLat] = useState(curPos.lat);
  const [lon, setLon] = useState(curPos.lon);

  const changedPosition = (lat, lon) =>{
    setLat(lat);
    setLon(lon);
  };

  return (
    <ModalWindow isOpen={show} toggle={onClose} centered>
      <ModalHeader toggle={onClose}>
        {title}
      </ModalHeader>
      <ModalBody>
        <MapComponent
          isMarkerShown={isMarkerShown}
          curPos={curPos}
          changedPosition={(lat, lon) => changedPosition(lat, lon)}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="white" onClick={onClose}>
          Cancel
        </Button>
        <Button color="danger" onClick={() => onConfirm(lat, lon)}>
          {confirmText}
        </Button>
      </ModalFooter>
    </ModalWindow>
  );
};

MapModal.defaultProps = {
  confirmText: 'Bestätigen'
};

export default MapModal;
