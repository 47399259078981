import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Avatar,
  Checkbox,
  makeStyles
} from '@material-ui/core';

import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { getDatabase, remove, ref as dbRef, onValue, update } from "firebase/database";
import { useDispatch } from 'react-redux';

import { setLoading } from '../../redux/actions';
import ConfirmActionModal from '../../components/confirm_action_modal';
import { NotificationManager } from '../../components/react-notifications';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({ className, ...rest }) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [isShowConfirm, toggleConfirm] = useState(false);
  const [delId, setDelId] = useState('');
  const dispatch = useDispatch();

  const db = getDatabase();

  useEffect(() => {
    let arrTmp = [];

    dispatch(setLoading(true));

    return onValue(dbRef(db, '/visitors'), (snapshot) => {
      snapshot.forEach(function (childSnapshot) {
        var childKey = childSnapshot.key;
        var childData = childSnapshot.val();
        childData['id'] = childKey;

        arrTmp.push(childData);
      });
      setRows(arrTmp);

      dispatch(setLoading(false));

    }, {
      onlyOnce: true
    });
  }, [db, dispatch]);

  const onRemoveClick = () => {
    onDelete(delId);
  };

  const onDelete = id => {
    dispatch(setLoading(true));

    remove(dbRef(db, 'visitors/' + id))
      .then(() => {
        console.log("----------- delete success");

        const postData = rows.filter(row => row.id === id)[0];

        const newRows = rows.filter(row => row.id !== id);
        setRows(newRows);

        const updates = {};
        updates['/blockedUser/' + id] = postData;

        update(dbRef(db), updates)
          .then(() => {
            console.log("++++++++ add success");

            dispatch(setLoading(false));

            NotificationManager.success(
              'Erfolgreich gelöscht',
              "Alert",
              3000,
              null,
              null,
              ''
            );
          })
          .catch((error) => {
            console.log("+++++  add ", error);
            dispatch(setLoading(false));
            NotificationManager.warning(
              'Fehlgeschlagen',
              "Alert",
              3000,
              null,
              null,
              ''
            );
          });
      })
      .catch(error => {
        console.log("------------ delete ", error);
        dispatch(setLoading(false));
        NotificationManager.warning(
          'Löschen fehlgeschlagen',
          "Alert",
          3000,
          null,
          null,
          ''
        );
      });
  }

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const getHumanDate = (timestamp) => {
    const d = new Date(timestamp)
    return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <ConfirmActionModal
        title="Löschen bestätigen"
        text="Möchtest du diesen Eintrag wirklich löschen"
        onConfirm={() => { onRemoveClick(); toggleConfirm(false); }}
        show={isShowConfirm}
        onClose={() => toggleConfirm(false)}
        confirmText="Löschen"
      />
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell rowSpan={2} align="left" />
                <TableCell rowSpan={2}>
                  Profilbild
                </TableCell>
                <TableCell rowSpan={2}>
                  UID
                </TableCell>
                <TableCell rowSpan={2}>
                  Name
                </TableCell>
                <TableCell rowSpan={2}>
                  Erstellt
                </TableCell>
                <TableCell rowSpan={2}>
                  Markierte Formationen
                </TableCell>
                <TableCell colSpan={4} align="center">
                  Standort
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Breitengrad
                </TableCell>
                <TableCell>
                  Längengrad
                </TableCell>
                <TableCell>
                  Zuletzt aktualisiert
                </TableCell>
                <TableCell>
                  isSharing
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(page * limit, (page + 1) * limit).map((row) => (
                <TableRow
                  hover
                  key={row.id}
                >
                  <TableCell className={classes.selectTableCell}>
                    <IconButton
                      aria-label="delete"
                      onClick={() => { toggleConfirm(true); setDelId(row.id); }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <Avatar
                      className={classes.avatar}
                      src={row.profilepicture === undefined ? '' : ((row.profilepicture.includes('http://') || row.profilepicture.includes('https://')) ? row.profilepicture : 'data:image/png;base64, ' + row.profilepicture)}
                    />
                  </TableCell>
                  <TableCell>
                    {row.userid}
                  </TableCell>
                  <TableCell>
                    {row.username}
                  </TableCell>
                  <TableCell>
                    {row.datetime}
                  </TableCell>
                  <TableCell>
                    {row.favouriteformations}
                  </TableCell>
                  <TableCell>
                    {row['location'] === undefined ? '' : row['location']['lat']}
                  </TableCell>
                  <TableCell>
                    {row['location'] === undefined ? '' : row['location']['lon']}
                  </TableCell>
                  <TableCell>
                    {row['location'] === undefined ? '' : row['location']['timestamp'] === undefined ? '' : getHumanDate(row['location']['timestamp']['timestamp'])}
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      disabled
                      checked={row['location'] === undefined ? false : row['location']['locationSharing']}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={rows.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25, 100]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;
