import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  makeStyles
} from '@material-ui/core';

import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { getDatabase, remove, ref as dbRef, onValue } from "firebase/database";
import { useDispatch } from 'react-redux';

import { setLoading } from '../../redux/actions';
import ConfirmActionModal from '../../components/confirm_action_modal';
import { NotificationManager } from '../../components/react-notifications';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({ className, ...rest }) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);
  const [isShowConfirm, toggleConfirm] = useState(false);
  const [delId, setDelId] = useState('');
  const dispatch = useDispatch();

  const db = getDatabase();

  useEffect(() => {
    let arrTmp = [];

    dispatch(setLoading(true));

    return onValue(dbRef(db, '/socialmedia'), (snapshot) => {
      snapshot.forEach(function (childSnapshot) {
        var childKey = childSnapshot.key;
        var childData = childSnapshot.val();
        childData['id'] = childKey;

        arrTmp.push(childData);
      });
      arrTmp.reverse();
      setRows(arrTmp);

      dispatch(setLoading(false));

    }, {
      onlyOnce: true
    });
  }, [db, dispatch]);

  const onRemoveClick = () => {
    onDelete(delId);
  };

  const onDelete = id => {
    dispatch(setLoading(true));

    remove(dbRef(db, 'socialmedia/' + id))
      .then(() => {
        console.log("----------- delete success");
        const newRows = rows.filter(row => row.id !== id);
        setRows(newRows);
        dispatch(setLoading(false));

        NotificationManager.success(
          'Erfolgreich gelöscht',
          "Alert",
          3000,
          null,
          null,
          ''
        );
      })
      .catch(error => {
        console.log("------------ delete ", error);
        dispatch(setLoading(false));
        NotificationManager.warning(
          'Löschen fehlgeschlagen',
          "Alert",
          3000,
          null,
          null,
          ''
        );
      });
  }

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const gotoNewPage = (url) => {
    console.log(url);
    window.open(url, "_blank");
  };

  function convertEpoch(epoch) {
    const d = new Date(epoch * 1000);
    let day = d.getDate();
    let month = d.getMonth() + 1;
    let hours = d.getHours();
    let minutes = d.getMinutes();
    day = day > 9 ? day : '0' + day;
    month = month > 9 ? month : '0' + month;
    hours = hours > 9 ? hours : '0' + hours;
    minutes = minutes > 9 ? minutes : '0' + minutes;
    return day + '.' + month + '.' + d.getFullYear() + ' ' + hours + ':' + minutes;
  }

  function getNetworkIcon(networkname) {
    let icon = "";
    if (networkname === 'Instagram Business') {
      icon = "<img style='max-width: 24px;' src='../../static/images/logo_instagram.png' />";
    } else if (networkname === 'YouTube') {
      icon = "<img style='max-width: 24px;' src='../../static/images/logo_youtube.png' />";
    } else if (networkname === 'Twitter') {
      icon = "<img style='max-width: 24px;' src='../../static/images/logo_twitter.png' />";
    } else if (networkname === 'Vimeo') {
      icon = "<img style='max-width: 24px;' src='../../static/images/logo_vimeo.png' />";
    } else if (networkname === 'RSS') {
      icon = "<img style='max-width: 24px;' src='../../static/images/logo_rss.png' />";
    } else {
      icon = '';
    }
    return icon;
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <ConfirmActionModal
        title="Löschen bestätigen"
        text="Möchtest du diesen Eintrag wirklich löschen"
        onConfirm={() => { onRemoveClick(); toggleConfirm(false); }}
        show={isShowConfirm}
        onClose={() => toggleConfirm(false)}
        confirmText="Löschen"
      />
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left" />
                <TableCell>
                  Network
                </TableCell>
                <TableCell>
                  Created
                </TableCell>
                <TableCell>
                  Image URL
                </TableCell>
                <TableCell>
                  Username
                </TableCell>
                <TableCell>
                  Source URL
                </TableCell>
                <TableCell>
                  Text
                </TableCell>
                <TableCell>
                  Video URL
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(page * limit, (page + 1) * limit).map((row) => (
                <TableRow
                  hover
                  key={row.id}
                  onClick={() => {console.log("row click================="); gotoNewPage(row.sourcelink);}}
                >
                  <TableCell className={classes.selectTableCell}>
                    <IconButton
                      aria-label="delete"
                      onClick={(e) => { e.stopPropagation(); toggleConfirm(true); setDelId(row.id); }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <div dangerouslySetInnerHTML={{ __html: getNetworkIcon(row.networkname) }} />
                  </TableCell>
                  {/*<TableCell>*/}
                  {/*  {convertEpoch(row.addedtofirebase)}*/}
                  {/*</TableCell>*/}
                  <TableCell>
                    {convertEpoch(row.creationtime)}
                  </TableCell>
                  <TableCell>
                    <img src={row.imagelink} />
                  </TableCell>
                  <TableCell>
                    {row.postusername}
                  </TableCell>
                  <TableCell>
                    {row.sourcelink}
                  </TableCell>
                  <TableCell>
                    {decodeURI(row.text)}
                  </TableCell>
                  <TableCell>
                    {row.videolink}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={rows.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25, 100]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;
