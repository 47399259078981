import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Input,
  makeStyles
} from '@material-ui/core';

import EditIcon from "@material-ui/icons/EditOutlined";
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { getDatabase, remove, set, ref as dbRef, push, child, update } from "firebase/database";
import { useDispatch } from 'react-redux';

import { setLoading } from '../../redux/actions';
import ConfirmActionModal from '../../components/confirm_action_modal';
import { NotificationManager } from '../../components/react-notifications';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
    width: 200,
    height: 200
  },
  avatar_edit: {
    width: 160,
    display: 'flex',
    alginItems: 'center'
  },
  editLocation: {
    marginLeft: theme.spacing(2)
  },
  locationDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  datePicker: {
    width: 250
  }
}));

const CustomTableCell = ({ row, name, onChange }) => {
  const classes = useStyles();
  const { isEditMode } = row;

  const start = row['start'] === undefined ? '' : new Date(row['start'] * 1000);
  const end = row['end'] === undefined ? '' : new Date(row['end'] * 1000);

  const [selectedStartDate, setSelectedStartDate] = useState(new Date(row['start'] * 1000));
  const [selectedEndDate, setSelectedEndDate] = useState(new Date(row['end'] * 1000));

  const changedStartDate = (event) => {
    console.log("-----------------", event);
    const d = new Date(event);
    const e = { target: { name: 'start', value: d.getTime() / 1000 } };
    onChange(e, row);
  }

  const changedEndDate = (event) => {
    console.log("-----------------", event);
    const d = new Date(event);
    const e = { target: { name: 'end', value: d.getTime() / 1000 } };
    onChange(e, row);
  }

  return (
    <TableCell align="left">
      {(name === 'name') && (isEditMode ? (
        <Input
          value={row[name] === undefined ? '' : row[name]}
          name={name}
          onChange={e => onChange(e, row)}
          className={classes.input}
        />
      ) : (
          row[name]
        ))}

      {(name === 'start') && (isEditMode ? (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            value={selectedStartDate}
            onChange={(e) => { setSelectedStartDate(e); changedStartDate(e); }}
            label=""
            ampm={false}
            onError={console.log}
            minDate={new Date("2022-01-01T00:00")}
            format="dd.MM.yyyy HH:mm"
            className={classes.datePicker}
          />
        </MuiPickersUtilsProvider>
      ) : (
          row['start'] === undefined ? '' : (start.getFullYear() + '-' + (start.getMonth() + 1) + '-' + start.getDate() + ' ' + start.getHours() + ":" + start.getMinutes() + ":" + start.getSeconds())
        ))}

      {(name === 'end') && (isEditMode ? (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            value={selectedEndDate}
            onChange={(e) => { setSelectedEndDate(e); changedEndDate(e); }}
            label=""
            ampm={false}
            onError={console.log}
            minDate={new Date("2022-01-01T00:00")}
            format="dd.MM.yyyy HH:mm"
            className={classes.datePicker}
          />
        </MuiPickersUtilsProvider>
      ) : (
          row['end'] === undefined ? '' : (end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate() + ' ' + end.getHours() + ":" + end.getMinutes() + ":" + end.getSeconds())
        ))}

    </TableCell>
  );
};

const ExpandableTableRow = ({ children, programs, onSaveProgram, timeKey, ...otherProps }) => {
  const classes = useStyles();
  const [rows, setRows] = useState(programs);
  const [isExpanded, setIsExpanded] = useState(false);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);
  const [previous, setPrevious] = useState({});
  const [isShowConfirm, toggleConfirm] = useState(false);
  const [delId, setDelId] = useState('');

  const dispatch = useDispatch();

  const db = getDatabase();

  const onChange = (e, row) => {
    if (!previous[row.id]) {
      setPrevious(state => ({ ...state, [row.id]: row }));
    }
    const value = e.target.value;
    const name = e.target.name;
    const { id } = row;
    const newRows = rows.map(row => {
      if (row.id === id) {
        if (name === 'lat_lon') {
          return { ...row, location: { lat: e.target.lat, lon: e.target.lon } };
        } else {
          return { ...row, [name]: value };
        }
      }
      return row;
    });
    setRows(newRows);
  };

  const onToggleEditMode = id => {
    setRows(state => {
      return state.map(row => {
        if (row.id === id) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };

  const onSave = (id, row) => {
    dispatch(setLoading(true));

    console.log("key  --- ", timeKey);
    set(dbRef(db, 'timetable/' + timeKey + '/programm/' + id), row)
      .then(() => {
        // Data saved successfully!
        console.log("+++++++++ update success");
        setPrevious(state => {
          delete state[id];
          return state;
        });

        onToggleEditMode(id);

        onSaveProgram(rows);

        dispatch(setLoading(false));

        NotificationManager.success(
          'Erfolgreich geändert',
          "Alert",
          3000,
          null,
          null,
          ''
        );
      })
      .catch((error) => {
        // The write failed...
        console.log("----------- update ", error);
        dispatch(setLoading(false));

        NotificationManager.warning(
          'Änderung fehlgeschlagen',
          "Alert",
          3000,
          null,
          null,
          ''
        );
      });
  };

  const onRevert = id => {
    const newRows = rows.map(row => {
      if (row.id === id) {
        return previous[id] ? previous[id] : row;
      }
      return row;
    });
    setRows(newRows);
    setPrevious(state => {
      delete state[id];
      return state;
    });
    onToggleEditMode(id);
  };

  const onRemoveClick = () => {
    onDelete(delId);
  };

  const onDelete = id => {
    dispatch(setLoading(true));

    remove(dbRef(db, 'timetable/' + timeKey + '/programm/' + id))
      .then(() => {
        console.log("----------- delete success");
        const newRows = rows.filter(row => row.id !== id);
        setRows(newRows);
        onSaveProgram(newRows);

        dispatch(setLoading(false));

        NotificationManager.success(
          'Erfolgreich gelöscht',
          "Alert",
          3000,
          null,
          null,
          ''
        );
      })
      .catch(error => {
        console.log("------------ delete ", error);
        dispatch(setLoading(false));
        NotificationManager.warning(
          'Löschen fehlgeschlagen',
          "Alert",
          3000,
          null,
          null,
          ''
        );
      });
  }

  const onAdd = () => {
    dispatch(setLoading(true));

    const db = getDatabase();
    // Get a key for a new Post.
    const newPostKey = push(child(dbRef(db), 'timetable/' + timeKey + '/programm/')).key;

    const date = new Date();

    const postData = {
      id: newPostKey,
      isEditMode: true,
      start: date.getTime() / 1000,
      end: date.getTime() / 1000
    };

    // Write the new post's data simultaneously in the posts list and the user's post list.
    const updates = {};
    updates['/timetable/' + timeKey + '/programm/' + newPostKey] = postData;

    update(dbRef(db), updates)
      .then(() => {
        console.log("++++++++ add success");
        let newRows = rows.map(row => row);
        console.log(newRows);
        newRows.unshift(postData);
        console.log(newRows);
        setRows(newRows);

        onSaveProgram(newRows);

        setPage(0);

        dispatch(setLoading(false));

        NotificationManager.success(
          'Erfolgreich hinzugefügt',
          "Alert",
          3000,
          null,
          null,
          ''
        );
      })
      .catch((error) => {
        console.log("+++++  add ", error);
        dispatch(setLoading(false));
        NotificationManager.warning(
          'Hinzufügen fehlgeschlagen',
          "Alert",
          3000,
          null,
          null,
          ''
        );
      });
  }

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <TableRow {...otherProps}>
        <TableCell padding="checkbox">
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {!isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell padding="checkbox" />
          <TableCell></TableCell>
          <TableCell>
            Programm
            <Button
              color="primary"
              variant="contained"
              onClick={() => onAdd()}
            >
              Hinzufügen
            </Button>
          </TableCell>
          <TableCell colSpan={6}>
            <Card
              className={classes.root}
            >
              <ConfirmActionModal
                title="Löschen bestätigen"
                text="Möchtest du diesen Eintrag wirklich löschen"
                onConfirm={() => { onRemoveClick(); toggleConfirm(false); }}
                show={isShowConfirm}
                onClose={() => toggleConfirm(false)}
                confirmText="Löschen"
              />
              <PerfectScrollbar>
                <Box minWidth={1050}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left"></TableCell>
                        <TableCell>name</TableCell>
                        <TableCell>start</TableCell>
                        <TableCell>end</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow
                          hover
                          key={row.id}
                        >
                          <TableCell className={classes.selectTableCell}>
                            {row.isEditMode ? (
                              <>
                                <IconButton
                                  aria-label="done"
                                  onClick={() => onSave(row.id, row)}
                                >
                                  <DoneIcon />
                                </IconButton>
                                <IconButton
                                  aria-label="revert"
                                  onClick={() => onRevert(row.id)}
                                >
                                  <RevertIcon />
                                </IconButton>
                              </>
                            ) : (
                                <>
                                  <IconButton
                                    aria-label="edit"
                                    onClick={() => onToggleEditMode(row.id)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton
                                    aria-label="delete"
                                    onClick={() => { toggleConfirm(true); setDelId(row.id); }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </>
                              )}
                          </TableCell>
                          <CustomTableCell {...{ row, name: "name", onChange }} />
                          <CustomTableCell {...{ row, name: "start", onChange }} />
                          <CustomTableCell {...{ row, name: "end", onChange }} />
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
              <TablePagination
                component="div"
                count={rows.length}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25, 100]}
              />
            </Card>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default ExpandableTableRow;
