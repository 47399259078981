import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useRoutes, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import 'bootstrap/dist/css/bootstrap.min.css';
import theme from 'src/theme';
import routes from 'src/routes';
import routesLogin from 'src/routes_login';
import NotificationContainer from './components/react-notifications/NotificationContainer';
import './sass/index.scss';

const App = (props) => {
  const routing = useRoutes(routes);
  const routingLogin = useRoutes(routesLogin);
  const navigate = useNavigate();

  const { loginUser } = props;

  useEffect(() => {
    navigate('/', { replace: true });
  }, [loginUser]);

  //console.log("loginuser = ", loginUser);

  return (
    <ThemeProvider theme={theme}>
      <NotificationContainer />
      <GlobalStyles />
      {loginUser ? routing : routingLogin}
    </ThemeProvider>
  );
};

const mapStateToProps = ({ authUser }) => {
  const { user: loginUser } = authUser;
  return { loginUser };
};
const mapActionsToProps = {};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(App);

