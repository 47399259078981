import React from 'react';
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

const MapComponent = compose(
  withProps({
    googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDgzGzvWjouxZIsrwcob0X1ZqYzIOaKZMY&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '500px' }} />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const { isMarkerShown, curPos, changedPosition } = props;
  const refs = {};

  const onMarkerMounted = (ref) => {
    refs.marker = ref;
  }

  const onPositionChanged = () => {
    const position = refs.marker.getPosition();
    // console.log(position.lat(), position.lng());
    changedPosition(position.lat(), position.lng());
  }

  return (
    <GoogleMap
      defaultZoom={16}
      defaultCenter={{ lat: curPos.lat, lng: curPos.lon }}
    >
      {isMarkerShown &&
        <Marker
          position={{ lat: curPos.lat, lng: curPos.lon }}
          draggable={true} 
          ref={onMarkerMounted}
          onPositionChanged={onPositionChanged}
        />
      }
    </GoogleMap>
  );
});

export default MapComponent;
