import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { connect } from "react-redux";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import { loginUser } from '../../redux/actions';
import { NotificationManager } from '../../components/react-notifications';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: 'url("static/images/bg.jpg")',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  loginbox: {
    backgroundColor: 'rgba(217,217,217,0.85)',
    borderRadius: 16,
    padding: theme.spacing(3),
  }
}));

const LoginView = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    if (props.error) {
      console.log("props.error === ", props.error);
      NotificationManager.warning(
        props.error.message,
        "Alert",
        3000,
        null,
        null,
        ''
      );
    }
  }, [props]);

  const onUserLogin = (values) => {
    console.log(values);
    if (!props.loading) {
      if (values.email !== "" && values.password !== "") {
        props.loginUser(values, navigate);
      }
    }
  }

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm" className={classes.loginbox}>
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('E-Mail ist ungültig').max(255).required('Die E-Mail Adresse wird benötigt!'),
              password: Yup.string().max(255).required('Bitte gib ein Passwort ein!')
            })}
            onSubmit={onUserLogin}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values
              }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Login
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="E-Mail Adresse"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Passwort"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={props.loading ? true : false}
                  >
                    Anmelden &nbsp;&nbsp;&nbsp;&nbsp;{props.loading && <CircularProgress size={30} />}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

const mapStateToProps = ({ authUser }) => {
  const { user, loading, error } = authUser;
  return { user, loading, error };
};

export default connect(
  mapStateToProps,
  {
    loginUser
  }
)(LoginView);
