import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Card,
  CardContent,
  Typography,
  makeStyles,
  Grid,
  Container,
} from '@material-ui/core';
import { getDatabase, ref, onValue } from 'firebase/database';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardContainer: {
    width: '30%', // 3 cards per line
    padding: '10px',
  },
  card: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  largeText: {
    fontSize: '2.5rem',
  },
  smallText: {
    fontSize: '1.5rem',
  },
}));

const Dashboard = ({ className, ...rest }) => {
  const classes = useStyles();
  const [itemCounts, setItemCounts] = useState({
    Nutzer: 0,
    Formationen: 0,
    SocialMediaEinträge: 0,
    Programmpunkte: 0,
    Toiletten: 0,
    Cliquekäller: 0,
    PfyfferUndDrummelschuele: 0,
    SchnitzelbanggBaize: 0,
    Verpflegungsstände: 0,
  });

  useEffect(() => {
    const db = getDatabase();

    const endpoints = [
      { name: 'Nutzer', path: '/users' },
      { name: 'Formationen', path: '/formations' },
      { name: 'Social Media Einträge', path: '/socialmedia' },
      { name: 'Programmpunkte', path: '/timetable' },
      { name: 'Toiletten', path: 'maplocations/toiletten' },
      { name: 'Cliquekäller', path: 'maplocations/cliquekaeller' },
      { name: 'Pfyffer & Drummelschuele', path: 'maplocations/pfeiferundtrommelschulen' },
      { name: 'Schnitzelbangg Baize', path: 'maplocations/schnitzelbangg' },
      { name: 'Verpflegungsstände', path: 'maplocations/verpflegung' },
    ];

    const updatedCounts = {};

    const fetchCounts = async () => {
      for (const endpoint of endpoints) {
        const dbRef = ref(db, endpoint.path);
        const snapshot = await onValue(dbRef);

        if (snapshot.exists()) {
          updatedCounts[endpoint.name] = snapshot.numChildren();
        }
      }

      setItemCounts(updatedCounts);
    };

    fetchCounts();
  }, []);

  return (
      <div className={classes.root}>
        <Container className={classes.container}>
          {Object.entries(itemCounts).map(([category, count]) => (
              <Grid item xs={4} className={classes.cardContainer} key={category}>
                <Card className={clsx(classes.card)}>
                  <CardContent>
                    <Typography className={classes.largeText} color="textPrimary" variant="h3">
                      {count}
                    </Typography>
                    <Typography className={classes.smallText} color="textSecondary" variant="body1">
                      {category}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
          ))}
        </Container>
      </div>
  );
};

Dashboard.propTypes = {
  className: PropTypes.string,
};

export default Dashboard;
