import React, {
  useState, useRef, forwardRef, useImperativeHandle, useEffect
} from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Input,
  IconButton,
  Button,
  ListItemIcon,
  ListItemText,
  InputLabel,
  FormControl,
  Select
} from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// Icons
import EditIcon from '@material-ui/icons/EditOutlined';
import DoneIcon from '@material-ui/icons/DoneAllTwoTone';
import RevertIcon from '@material-ui/icons/NotInterestedOutlined';
import UploadIcon from '@material-ui/icons/CloudUploadOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';

import {
  getDatabase, remove, set, push, child, update, ref as dbRef, onValue
} from 'firebase/database';
import {
  getStorage, ref as storageRef, uploadString, getDownloadURL, deleteObject
} from 'firebase/storage';
import { useDispatch } from 'react-redux';

import { MenuPropsClique, useStylesClique, optionsClique } from './utils';
import { setLoading } from '../../redux/actions';
import ConfirmActionModal from '../../components/confirm_action_modal';
import { NotificationManager } from '../../components/react-notifications';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  avatar_edit: {
    width: 160,
    display: 'flex',
    alginItems: 'center'
  },
  input: {
    width: 160
  }
}));

const CustomTableCell = ({ row, name, onChange }) => {
  const classes = useStyles();
  const { isEditMode } = row;

  const getType = (typ) => {
    let strType = '';
    switch (typ) {
      case 1:
        strType = 'Clique';
        break;
      case 2:
        strType = 'Gugge';
        break;
      case 3:
        strType = 'Chaise';
        break;
      case 4:
        strType = 'Schyssdräggziigli';
        break;
      case 5:
        strType = 'Waage';
        break;
      case 6:
        strType = 'Schnitzelbangg';
        break;
      case 7:
        strType = 'Ainzelmassge';
        break;
      default:
        strType = '';
        break;
    }
    return strType;
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [visval, setVisval] = useState(getType(row.typ));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    // console.log(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [selectedIndex, setSelectedIndex] = useState(row.typ - 1);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    const e = { target: { name: 'typ', value: index + 1 } };
    onChange(e, row);
    handleClose();
    // console.log(index);
    setVisval(getType(index + 1));
  };

  const cliquetype = row.cliquetype === undefined ? '' : row.cliquetype.replace(/\[|\]|"/g, '');
  const classesClique = useStylesClique();
  const [selectedClique, setSelectedClique] = useState(cliquetype === '' ? [] : cliquetype.split(','));

  const isAllSelectedClique = optionsClique.length > 0 && selectedClique.length === optionsClique.length;

  const handleChangeClique = (event) => {
    const { value } = event.target;
    let selClique = [];
    if (value[value.length - 1] === 'all') {
      setSelectedClique(selectedClique.length === optionsClique.length ? [] : optionsClique);
      selClique = selectedClique.length === optionsClique.length ? [] : optionsClique;
    } else {
      setSelectedClique(value);
      selClique = value;
    }

    let cliqueTxt = '["';
    cliqueTxt += selClique.join('","');
    cliqueTxt += '"]';
    const e = { target: { name: 'cliquetype', value: cliqueTxt } };
    onChange(e, row);
  };

  const handleFileInput = useRef(null);

  const onUploadBtnClick = () => {
    handleFileInput.current.click();
  };

  const onUploadProfile = (event) => {
    const files = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = document.createElement('img');
      img.onload = () => {
        const canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        const MAX_WIDTH = 2000;
        const MAX_HEIGHT = 2000;
        let { width } = img;
        let { height } = img;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
        canvas.width = width;
        canvas.height = height;
        ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        const dataurl = canvas.toDataURL('image/webp', '0.85');

        const d = new Date();
        const fileCreateTime = d.getTime().toString();
        const storage = getStorage();
        const sref = storageRef(storage, `formations/${fileCreateTime}`);

        uploadString(sref, dataurl, 'data_url').then((snapshot) => {
          console.log('Uploaded a data_url string!');
          getDownloadURL(snapshot.ref).then((downloadURL) => {
            console.log('File available at', downloadURL);

            const res = { target: { name: 'profilepicture', value: downloadURL } };
            onChange(res, row);
          });
        });
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(files);
  };

  const onCancelProfile = () => {
    const storage = getStorage();

    // Create a reference to the file to delete
    const desertRef = storageRef(storage, row.profilepicture);

    // Delete the file
    deleteObject(desertRef).then(() => {
      // File deleted successfully
      console.log('file deleted success');
    }).catch((error) => {
      // Uh-oh, an error occurred!
      console.log('delete ---', error);
    });

    const e = { target: { name: 'profilepicture', value: '' } };
    onChange(e, row);
  };

  return (
    <TableCell align="left">
      {name === 'cortege' && (isEditMode ? (
        <Checkbox
          name="cortege"
          checked={row[name] === undefined ? false : (row[name] === 1)}
          onChange={(event) => onChange(event, row)}
          value="true"
        />
      ) : (
        <Checkbox
          disabled
          checked={row[name] === undefined ? false : (row[name] === 1)}
        />
      ))}

      {name === 'profilepicture' && (isEditMode ? (
        <div className={classes.avatar_edit}>
          <Avatar
            className={classes.avatar}
            src={row[name]}
          />
          <IconButton
            aria-label="done"
            onClick={() => onUploadBtnClick()}
          >
            <UploadIcon />
          </IconButton>
          <input
            type="file"
            ref={handleFileInput}
            name="upload_file"
            style={{ display: 'none' }}
            onChange={onUploadProfile}
          />
          <IconButton
            aria-label="revert"
            onClick={() => onCancelProfile()}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ) : (
        <Avatar
          className={classes.avatar}
          src={row[name]}
        />
      ))}

      {name === 'typ' && (isEditMode ? (
        <div>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            {visval}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              data-my-value={1}
              selected={selectedIndex === 0}
              onClick={(event) => handleListItemClick(event, 0)}
            >
              Clique
            </MenuItem>
            <MenuItem
              data-my-value={2}
              selected={selectedIndex === 1}
              onClick={(event) => handleListItemClick(event, 1)}
            >
              Gugge
            </MenuItem>
            <MenuItem
              data-my-value={3}
              selected={selectedIndex === 2}
              onClick={(event) => handleListItemClick(event, 2)}
            >
              Chaise
            </MenuItem>
            <MenuItem
              data-my-value={4}
              selected={selectedIndex === 3}
              onClick={(event) => handleListItemClick(event, 3)}
            >
              Schyssdräggziigli
            </MenuItem>
            <MenuItem
              data-my-value={5}
              selected={selectedIndex === 4}
              onClick={(event) => handleListItemClick(event, 4)}
            >
              Waage
            </MenuItem>
            <MenuItem
              data-my-value={6}
              selected={selectedIndex === 5}
              onClick={(event) => handleListItemClick(event, 5)}
            >
              Schnitzelbangg
            </MenuItem>
            <MenuItem
              data-my-value={7}
              selected={selectedIndex === 6}
              onClick={(event) => handleListItemClick(event, 6)}
            >
              Ainzelmassge
            </MenuItem>
          </Menu>
        </div>
      ) : (
        getType(row[name])
      ))}

      {name === 'cliquetype' && (isEditMode ? (
        <FormControl className={classesClique.formControl}>
          <InputLabel id="mutiple-select-label" />
          <Select
            labelId="mutiple-select-label"
            multiple
            value={selectedClique}
            onChange={handleChangeClique}
            renderValue={(selectedClique) => selectedClique.join(', ')}
            MenuProps={MenuPropsClique}
          >
            <MenuItem
              value="all"
              classes={{
                root: isAllSelectedClique ? classesClique.selectedAll : ''
              }}
            >
              <ListItemIcon>
                <Checkbox
                  classes={{ indeterminate: classesClique.indeterminateColor }}
                  checked={isAllSelectedClique}
                  indeterminate={
                    selectedClique.length > 0 && selectedClique.length < optionsClique.length
                  }
                />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classesClique.selectAllText }}
                primary="Alle auswählen"
              />
            </MenuItem>
            {optionsClique.map((option) => (
              <MenuItem key={option} value={option}>
                <ListItemIcon>
                  <Checkbox checked={selectedClique.indexOf(option) > -1} />
                </ListItemIcon>
                <ListItemText primary={option} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        row[name]
      ))}

      {(name !== 'cliquetype' && name !== 'cortege' && name !== 'profilepicture' && name !== 'typ') && (isEditMode ? (
        <Input
          value={row[name] === undefined ? '' : row[name]}
          name={name}
          onChange={(e) => onChange(e, row)}
          className={classes.input}
        />
      ) : (
        row[name]
      ))}
    </TableCell>
  );
};

const Results = ({ className, ...rest }, ref) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [previous, setPrevious] = useState({});
  const [isShowConfirm, toggleConfirm] = useState(false);
  const [delId, setDelId] = useState('');
  const dispatch = useDispatch();

  const db = getDatabase();

  useEffect(() => {
    const arrTmp = [];
    const onMigrate = (id, row) => {
      set(dbRef(db, `formations/${id}`), row)
        .then(() => {
          // Data saved successfully!
          console.log('+++++++++ Migrate success');
        })
        .catch((error) => {
          // The write failed...
          console.log('----------- Migrate ', error);
        });
    };

    const storage = getStorage();

    dispatch(setLoading(true));

    return onValue(dbRef(db, '/formations'), (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        const childKey = childSnapshot.key;
        const childData = childSnapshot.val();
        childData.id = childKey;
        childData.isEditMode = false;

        arrTmp.push(childData);
      });

      const arrRows = [];
      const promises = arrTmp.reduce((rowPromise, row) => rowPromise.then(async () => {
        const d = new Date();
        const fileName = d.getTime();
        const sref = storageRef(storage, `formations/${fileName}`);

        const rowTmp = {};
        const keys = Object.keys(row);
        keys.forEach((key) => {
          rowTmp[key] = row[key];
        });

        const imgStr = rowTmp.profilepicture === undefined ? '' : rowTmp.profilepicture;
        console.log('111111');

        if (imgStr.includes('http://') || imgStr.includes('https://')) {
          arrRows.push(rowTmp);
        } else if (imgStr !== '') {
          await uploadString(sref, `data:image/png;base64, ${imgStr}`, 'data_url').then(async (snapshot) => {
            console.log('Uploaded a data_url string!');
            await getDownloadURL(snapshot.ref).then((downloadURL) => {
              console.log('File available at', downloadURL);

              rowTmp.profilepicture = downloadURL;

              onMigrate(rowTmp.id, rowTmp);

              arrRows.push(rowTmp);
            });
          });
        } else {
          console.log('image string null = ', imgStr);
          arrRows.push(rowTmp);
        }
      }).catch(console.error), Promise.resolve());

      promises.then(() => {
        console.log('result --- ', arrRows);
        setRows(arrRows);

        dispatch(setLoading(false));
      });
    }, {
      onlyOnce: true
    });
  }, [db, dispatch]);

  const onToggleEditMode = (id) => {
    setRows((state) => {
      return state.map((row) => {
        if (row.id === id) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };

  const onChange = (e, row) => {
    if (!previous[row.id]) {
      setPrevious((state) => ({ ...state, [row.id]: row }));
    }
    const { value } = e.target;
    const { name } = e.target;
    const { id } = row;
    const newRows = rows.map((row) => {
      if (row.id === id) {
        if (name === 'cortege') {
          return { ...row, [name]: 1 - (isNaN(row[name]) ? 0 : row[name]) };
        }
        return { ...row, [name]: value };
      }
      return row;
    });
    setRows(newRows);
  };

  const onSave = (id, row) => {
    dispatch(setLoading(true));

    set(dbRef(db, `formations/${id}`), row)
      .then(() => {
        // Data saved successfully!
        console.log('+++++++++ update success');
        setPrevious((state) => {
          delete state[id];
          return state;
        });

        onToggleEditMode(id);

        dispatch(setLoading(false));

        NotificationManager.success(
          'Erfolgreich geändert',
          'Alert',
          3000,
          null,
          null,
          ''
        );
      })
      .catch((error) => {
        // The write failed...
        console.log('----------- update ', error);
        dispatch(setLoading(false));

        NotificationManager.warning(
          'Änderung fehlgeschlagen',
          'Alert',
          3000,
          null,
          null,
          ''
        );
      });
  };

  const onRevert = (id) => {
    const newRows = rows.map((row) => {
      if (row.id === id) {
        return previous[id] ? previous[id] : row;
      }
      return row;
    });
    setRows(newRows);
    setPrevious((state) => {
      delete state[id];
      return state;
    });
    onToggleEditMode(id);
  };

  const onRemoveClick = () => {
    onDelete(delId);
  };

  const onDelete = (id) => {
    dispatch(setLoading(true));

    remove(dbRef(db, `formations/${id}`))
      .then(() => {
        console.log('----------- delete success');
        const newRows = rows.filter((row) => row.id !== id);
        setRows(newRows);
        dispatch(setLoading(false));

        NotificationManager.success(
          'Erfolgreich gelöscht',
          'Alert',
          3000,
          null,
          null,
          ''
        );
      })
      .catch((error) => {
        console.log('------------ delete ', error);
        dispatch(setLoading(false));
        NotificationManager.warning(
          'Löschen fehlgeschlagen',
          'Alert',
          3000,
          null,
          null,
          ''
        );
      });
  };

  useImperativeHandle(ref, () => ({
    onAdd() {
      dispatch(setLoading(true));

      const db = getDatabase();
      // Get a key for a new Post.
      const newPostKey = push(child(dbRef(db), 'formations')).key;

      const postData = {
        id: newPostKey,
        isEditMode: true,
        typ: 1
      };

      // Write the new post's data simultaneously in the posts list and the user's post list.
      const updates = {};
      updates[`/formations/${newPostKey}`] = postData;

      update(dbRef(db), updates)
        .then(() => {
          console.log('++++++++ add success');
          const newRows = rows.map((row) => row);
          console.log(newRows);
          newRows.unshift(postData);
          console.log(newRows);
          setRows(newRows);
          setPage(0);

          dispatch(setLoading(false));

          NotificationManager.success(
            'Erfolgreich erstellt',
            'Alert',
            3000,
            null,
            null,
            ''
          );
        })
        .catch((error) => {
          console.log('+++++  add ', error);
          dispatch(setLoading(false));
          NotificationManager.warning(
            'Hinzufügen fehlgeschlagen',
            'Alert',
            3000,
            null,
            null,
            ''
          );
        });
    }
  }));

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <ConfirmActionModal
        title="Löschen bestätigen"
        text="Möchtest du diesen Eintrag wirklich löschen"
        onConfirm={() => { onRemoveClick(); toggleConfirm(false); }}
        show={isShowConfirm}
        onClose={() => toggleConfirm(false)}
        confirmText="Löschen"
      />
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left" rowSpan={2} />
                <TableCell rowSpan={2}>
                  Profilbild
                </TableCell>
                <TableCell rowSpan={2}>
                  Name
                </TableCell>
                <TableCell rowSpan={2}>
                  Typ
                </TableCell>
                <TableCell rowSpan={2}>
                  Clique Typ
                </TableCell>
                <TableCell rowSpan={2}>
                  Kontakt Name
                </TableCell>
                <TableCell rowSpan={2}>
                  Kontakt E-Mail
                </TableCell>
                <TableCell rowSpan={2}>
                  Kontakt Website
                </TableCell>
                <TableCell rowSpan={2}>
                  Cortege
                </TableCell>
                <TableCell rowSpan={2}>
                  Goschdym
                </TableCell>
                <TableCell rowSpan={2}>
                  GPS Tracker ID
                </TableCell>
                <TableCell rowSpan={2}>
                  Sujet
                </TableCell>
                <TableCell rowSpan={2}>
                  Sujet Detail
                </TableCell>
                <TableCell rowSpan={2}>
                  Zeedel
                </TableCell>
                <TableCell colSpan={8}>
                  Standort
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Präzision</TableCell>
                <TableCell>Höhe</TableCell>
                <TableCell>Kurs</TableCell>
                <TableCell>Datum/Zeit</TableCell>
                <TableCell>Breitengrad</TableCell>
                <TableCell>Längengrad</TableCell>
                <TableCell>Geschw.</TableCell>
                <TableCell>Typ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(page * limit, (page + 1) * limit).map((row) => (
                <TableRow
                  hover
                  key={row.id}
                >
                  <TableCell className={classes.selectTableCell}>
                    {row.isEditMode ? (
                      <>
                        <IconButton
                          aria-label="done"
                          onClick={() => onSave(row.id, row)}
                        >
                          <DoneIcon />
                        </IconButton>
                        <IconButton
                          aria-label="revert"
                          onClick={() => onRevert(row.id)}
                        >
                          <RevertIcon />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <IconButton
                          aria-label="edit"
                          onClick={() => onToggleEditMode(row.id)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          onClick={() => { toggleConfirm(true); setDelId(row.id); }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                  <CustomTableCell {...{ row, name: 'profilepicture', onChange }} />
                  <CustomTableCell {...{ row, name: 'name', onChange }} />
                  <CustomTableCell {...{ row, name: 'typ', onChange }} />
                  <CustomTableCell {...{ row, name: 'cliquetype', onChange }} />
                  <CustomTableCell {...{ row, name: 'contactname', onChange }} />
                  <CustomTableCell {...{ row, name: 'contactmail', onChange }} />
                  <CustomTableCell {...{ row, name: 'contactwebsite', onChange }} />
                  <CustomTableCell {...{ row, name: 'cortege', onChange }} />
                  <CustomTableCell {...{ row, name: 'goschdym', onChange }} />
                  <CustomTableCell {...{ row, name: 'gpsdevice', onChange }} />
                  <CustomTableCell {...{ row, name: 'sujet', onChange }} />
                  <CustomTableCell {...{ row, name: 'sujetdetail', onChange }} />
                  <CustomTableCell {...{ row, name: 'zeedel', onChange }} />
                  <TableCell>{row.location === undefined ? '' : `${row.location.accuracy}m`}</TableCell>
                  <TableCell>{row.location === undefined ? '' : `${row.location.altitude}m`}</TableCell>
                  <TableCell>{row.location === undefined ? '' : `${row.location.course}°`}</TableCell>
                  <TableCell>{row.location === undefined ? '' : JSON.stringify(new Date(row.location.datetime * 1000).toISOString().slice(0, 19).replace(/-/g, '/').replace('T', ' '))}</TableCell>
                  <TableCell>{row.location === undefined ? '' : row.location.lat}</TableCell>
                  <TableCell>{row.location === undefined ? '' : row.location.lon}</TableCell>
                  <TableCell>{row.location === undefined ? '' : `${row.location.speed}kmh`}</TableCell>
                  <TableCell>{row.location === undefined ? '' : row.location.type}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={rows.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25, 100]}
      />
    </Card>
  );
};

export default forwardRef(Results);
