import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER_ERROR,
  SIGNUP_USER_ERROR,
  SET_LOADING
} from '../actions';

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history }
});
export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user
});
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message }
});

export const signupUser = (user, history) => ({
  type: SIGNUP_USER,
  payload: { user, history }
});
export const signupUserSuccess = (user) => ({
  type: SIGNUP_USER_SUCCESS,
  payload: user
});
export const signupUserError = (message) => ({
  type: SIGNUP_USER_ERROR,
  payload: { message }
});

export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history }
})
export const registerUserSuccess = (user) => ({
  type: REGISTER_USER_SUCCESS,
  payload: user
})
export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: { message }
})

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history }
});

export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: { loading }
});
