import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import ToiletTenListView from 'src/views/toiletten';
import FormationListView from 'src/views/formations';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import SocialMediaListView from 'src/views/socialmedia';
import SchnitzelbanggListView from 'src/views/schnitzelbangg';
import PfeiferundtrommelschulenListView from 'src/views/pfeiferundtrommelschulen';
import CliquekaellerListView from 'src/views/cliquekaeller';
import TimetableListView from 'src/views/timetable';
import VisitorsListView from 'src/views/visitors';
import DashboardView from "./views/dashboard/DashboardView";

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'account', element: <AccountView /> },
      { path: 'toiletten', element: <ToiletTenListView /> },
      { path: 'formations', element: <FormationListView /> },
      { path: 'socialmedia', element: <SocialMediaListView /> },
      { path: 'schnitzelbangg', element: <SchnitzelbanggListView /> },
      { path: 'pfeiferundtrommelschulen', element: <PfeiferundtrommelschulenListView /> },
      { path: 'cliquekaeller', element: <CliquekaellerListView /> },
      { path: 'timetable', element: <TimetableListView /> },
      { path: 'visitors', element: <VisitorsListView /> },
      { path: '*', element: <Navigate to="/app/dashboard" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/app/dashboard" /> }
    ]
  }
];

export default routes;
