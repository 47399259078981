import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Input,
  IconButton,
  makeStyles
} from '@material-ui/core';

import EditIcon from "@material-ui/icons/EditOutlined";
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import EditLocationIcon from "@material-ui/icons/EditLocationOutlined";

import { getDatabase, remove, set, ref as dbRef, onValue, push, child, update } from "firebase/database";
import { useDispatch } from 'react-redux';

import { setLoading } from '../../redux/actions';
import ConfirmActionModal from '../../components/confirm_action_modal';
import { NotificationManager } from '../../components/react-notifications';
import MapModal from '../../components/gmap';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  locationDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  editLocation: {
    marginLeft: theme.spacing(2)
  }
}));

const CustomTableCell = ({ row, name, onChange, showGMap }) => {
  const classes = useStyles();
  const { isEditMode } = row;

  const onEditLocation = () => {
    showGMap(row.id, row.location.lat, row.location.lon);
  };

  return (
    <TableCell align="left">
      {(name === 'lat') && (row['location'][name])}

      {(name === 'lon') && (isEditMode ? (
        <div className={classes.locationDiv}>
          {row['location'][name]}
          <IconButton
            aria-label="done"
            className={classes.editLocation}
            onClick={() => onEditLocation()}
          >
            <EditLocationIcon />
          </IconButton>
        </div>
      ) : (
          row['location'][name]
        ))}

      {(name === 'pfeiferschule_anfaenger') && (isEditMode ? (
        <Input
          value={row['pfeiferschule'] === undefined ? '' : row['pfeiferschule']['anfaenger'] === undefined ? '' : row['pfeiferschule']['anfaenger']}
          name={name}
          onChange={e => onChange(e, row)}
          className={classes.input}
        />
      ) : (
          row['pfeiferschule'] === undefined ? '' : row['pfeiferschule']['anfaenger'] === undefined ? '' : row['pfeiferschule']['anfaenger']
        ))}

      {(name === 'pfeiferschule_fortgeschrittene') && (isEditMode ? (
        <Input
          value={row['pfeiferschule'] === undefined ? '' : row['pfeiferschule']['fortgeschrittene'] === undefined ? '' : row['pfeiferschule']['fortgeschrittene']}
          name={name}
          onChange={e => onChange(e, row)}
          className={classes.input}
        />
      ) : (
          row['pfeiferschule'] === undefined ? '' : row['pfeiferschule']['fortgeschrittene'] === undefined ? '' : row['pfeiferschule']['fortgeschrittene']
        ))}

      {(name === 'pfeiferschule_jungegarde') && (isEditMode ? (
        <Input
          value={row['pfeiferschule'] === undefined ? '' : row['pfeiferschule']['jungegarde'] === undefined ? '' : row['pfeiferschule']['jungegarde']}
          name={name}
          onChange={e => onChange(e, row)}
          className={classes.input}
        />
      ) : (
          row['pfeiferschule'] === undefined ? '' : row['pfeiferschule']['jungegarde'] === undefined ? '' : row['pfeiferschule']['jungegarde']
        ))}

      {(name === 'trommelschule_anfaenger') && (isEditMode ? (
        <Input
          value={row['trommelschule'] === undefined ? '' : row['trommelschule']['anfaenger'] === undefined ? '' : row['trommelschule']['anfaenger']}
          name={name}
          onChange={e => onChange(e, row)}
          className={classes.input}
        />
      ) : (
          row['trommelschule'] === undefined ? '' : row['trommelschule']['anfaenger'] === undefined ? '' : row['trommelschule']['anfaenger']
        ))}

      {(name === 'trommelschule_fortgeschrittene') && (isEditMode ? (
        <Input
          value={row['trommelschule'] === undefined ? '' : row['trommelschule']['fortgeschrittene'] === undefined ? '' : row['trommelschule']['fortgeschrittene']}
          name={name}
          onChange={e => onChange(e, row)}
          className={classes.input}
        />
      ) : (
          row['trommelschule'] === undefined ? '' : row['trommelschule']['fortgeschrittene'] === undefined ? '' : row['trommelschule']['fortgeschrittene']
        ))}

      {(name === 'trommelschule_jungegarde') && (isEditMode ? (
        <Input
          value={row['trommelschule'] === undefined ? '' : row['trommelschule']['jungegarde'] === undefined ? '' : row['trommelschule']['jungegarde']}
          name={name}
          onChange={e => onChange(e, row)}
          className={classes.input}
        />
      ) : (
          row['trommelschule'] === undefined ? '' : row['trommelschule']['jungegarde'] === undefined ? '' : row['trommelschule']['jungegarde']
        ))}

      {(name !== 'lat' &&
        name !== 'lon' &&
        name !== 'pfeiferschule_anfaenger' &&
        name !== 'pfeiferschule_fortgeschrittene' &&
        name !== 'pfeiferschule_jungegarde' &&
        name !== 'trommelschule_anfaenger' &&
        name !== 'trommelschule_fortgeschrittene' &&
        name !== 'trommelschule_jungegarde') && (isEditMode ? (
          <Input
            value={row[name] === undefined ? '' : row[name]}
            name={name}
            onChange={e => onChange(e, row)}
            className={classes.input}
          />
        ) : (
            row[name]
          ))}
    </TableCell>
  );
};

const Results = ({ className, ...rest }, ref) => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [previous, setPrevious] = useState({});
  const [isShowConfirm, toggleConfirm] = useState(false);
  const [delId, setDelId] = useState('');
  const [isShowMap, setIsShowMap] = useState(false);
  const [selId, setSelId] = useState('');
  const [curLat, setCurLat] = useState(0);
  const [curLon, setCurLon] = useState(0);
  const dispatch = useDispatch();

  const db = getDatabase();

  useEffect(() => {
    let arrTmp = [];

    dispatch(setLoading(true));

    navigator.geolocation.getCurrentPosition(function (position) {
      console.log(position);
      setCurLat(position.coords.latitude);
      setCurLon(position.coords.longitude);
    });

    return onValue(dbRef(db, '/maplocations/pfeiferundtrommelschulen'), (snapshot) => {
      snapshot.forEach(function (childSnapshot) {
        var childKey = childSnapshot.key;
        var childData = childSnapshot.val();
        childData['id'] = childKey;
        childData['isEditMode'] = false;

        arrTmp.push(childData);
      });
      setRows(arrTmp);

      dispatch(setLoading(false));

    }, {
      onlyOnce: true
    });
  }, [db, dispatch]);

  const onChange = (e, row) => {
    if (!previous[row.id]) {
      setPrevious(state => ({ ...state, [row.id]: row }));
    }
    const value = e.target.value;
    const name = e.target.name;
    const { id } = row;
    const newRows = rows.map(row => {
      if (row.id === id) {
        if (name === 'lat_lon') {
          return { ...row, location: { lat: e.target.lat, lon: e.target.lon } };
        } else if (name === 'pfeiferschule_anfaenger') {
          return {
            ...row, pfeiferschule: {
              anfaenger: value,
              fortgeschrittene: row['pfeiferschule'] === undefined ? '' : row['pfeiferschule']['fortgeschrittene'] === undefined ? '' : row['pfeiferschule']['fortgeschrittene'],
              jungegarde: row['pfeiferschule'] === undefined ? '' : row['pfeiferschule']['jungegarde'] === undefined ? '' : row['pfeiferschule']['jungegarde']
            }
          };
        } else if (name === 'pfeiferschule_fortgeschrittene') {
          return {
            ...row, pfeiferschule: {
              anfaenger: row['pfeiferschule'] === undefined ? '' : row['pfeiferschule']['anfaenger'] === undefined ? '' : row['pfeiferschule']['anfaenger'],
              fortgeschrittene: value,
              jungegarde: row['pfeiferschule'] === undefined ? '' : row['pfeiferschule']['jungegarde'] === undefined ? '' : row['pfeiferschule']['jungegarde']
            }
          };
        } else if (name === 'pfeiferschule_jungegarde') {
          return {
            ...row, pfeiferschule: {
              anfaenger: row['pfeiferschule'] === undefined ? '' : row['pfeiferschule']['anfaenger'] === undefined ? '' : row['pfeiferschule']['anfaenger'],
              fortgeschrittene: row['pfeiferschule'] === undefined ? '' : row['pfeiferschule']['fortgeschrittene'] === undefined ? '' : row['pfeiferschule']['fortgeschrittene'],
              jungegarde: value
            }
          };
        } else if (name === 'trommelschule_anfaenger') {
          return {
            ...row, trommelschule: {
              anfaenger: value,
              fortgeschrittene: row['trommelschule'] === undefined ? '' : row['trommelschule']['fortgeschrittene'] === undefined ? '' : row['trommelschule']['fortgeschrittene'],
              jungegarde: row['trommelschule'] === undefined ? '' : row['trommelschule']['jungegarde'] === undefined ? '' : row['trommelschule']['jungegarde']
            }
          };
        } else if (name === 'trommelschule_fortgeschrittene') {
          return {
            ...row, trommelschule: {
              anfaenger: row['trommelschule'] === undefined ? '' : row['trommelschule']['anfaenger'] === undefined ? '' : row['trommelschule']['anfaenger'],
              fortgeschrittene: value,
              jungegarde: row['trommelschule'] === undefined ? '' : row['trommelschule']['jungegarde'] === undefined ? '' : row['trommelschule']['jungegarde']
            }
          };
        } else if (name === 'trommelschule_jungegarde') {
          return {
            ...row, trommelschule: {
              anfaenger: row['trommelschule'] === undefined ? '' : row['trommelschule']['anfaenger'] === undefined ? '' : row['trommelschule']['anfaenger'],
              fortgeschrittene: row['trommelschule'] === undefined ? '' : row['trommelschule']['fortgeschrittene'] === undefined ? '' : row['trommelschule']['fortgeschrittene'],
              jungegarde: value
            }
          };
        } else {
          return { ...row, [name]: value };
        }
      }
      return row;
    });
    setRows(newRows);
  };

  const onToggleEditMode = id => {
    setRows(state => {
      return state.map(row => {
        if (row.id === id) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };

  const onSave = (id, row) => {
    dispatch(setLoading(true));

    set(dbRef(db, 'maplocations/pfeiferundtrommelschulen/' + id), row)
      .then(() => {
        // Data saved successfully!
        console.log("+++++++++ update success");
        setPrevious(state => {
          delete state[id];
          return state;
        });

        onToggleEditMode(id);

        dispatch(setLoading(false));

        NotificationManager.success(
          'Erfolgreich geändert',
          "Alert",
          3000,
          null,
          null,
          ''
        );
      })
      .catch((error) => {
        // The write failed...
        console.log("----------- update ", error);
        dispatch(setLoading(false));

        NotificationManager.warning(
          'Änderung fehlgeschlagen',
          "Alert",
          3000,
          null,
          null,
          ''
        );
      });
  };

  const onRevert = id => {
    const newRows = rows.map(row => {
      if (row.id === id) {
        return previous[id] ? previous[id] : row;
      }
      return row;
    });
    setRows(newRows);
    setPrevious(state => {
      delete state[id];
      return state;
    });
    onToggleEditMode(id);
  };

  const onRemoveClick = () => {
    onDelete(delId);
  };

  const onDelete = id => {
    dispatch(setLoading(true));

    remove(dbRef(db, 'maplocations/pfeiferundtrommelschulen/' + id))
      .then(() => {
        console.log("----------- delete success");
        const newRows = rows.filter(row => row.id !== id);
        setRows(newRows);
        dispatch(setLoading(false));

        NotificationManager.success(
          'Erfolgreich gelöscht',
          "Alert",
          3000,
          null,
          null,
          ''
        );
      })
      .catch(error => {
        console.log("------------ delete ", error);
        dispatch(setLoading(false));
        NotificationManager.warning(
          'Löschen fehlgeschlagen',
          "Alert",
          3000,
          null,
          null,
          ''
        );
      });
  }

  useImperativeHandle(ref, () => ({
    onAdd() {
      dispatch(setLoading(true));

      const db = getDatabase();
      // Get a key for a new Post.
      const newPostKey = push(child(dbRef(db), 'maplocations/pfeiferundtrommelschulen/')).key;

      const postData = {
        id: newPostKey,
        isEditMode: true,
        location: {
          lat: curLat,
          lon: curLon
        }
      };

      // Write the new post's data simultaneously in the posts list and the user's post list.
      const updates = {};
      updates['/maplocations/pfeiferundtrommelschulen/' + newPostKey] = postData;

      update(dbRef(db), updates)
        .then(() => {
          console.log("++++++++ add success");
          let newRows = rows.map(row => row);
          console.log(newRows);
          newRows.unshift(postData);
          console.log(newRows);
          setRows(newRows);
          setPage(0);

          dispatch(setLoading(false));

          NotificationManager.success(
            'Erfolgreich hinzugefügt',
            "Alert",
            3000,
            null,
            null,
            ''
          );
        })
        .catch((error) => {
          console.log("+++++  add ", error);
          dispatch(setLoading(false));
          NotificationManager.warning(
            'Hinzufügen fehlgeschlagen',
            "Alert",
            3000,
            null,
            null,
            ''
          );
        });
    }
  }));

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const showGMap = (id, lat, lon) => {
    setSelId(id);
    setCurLat(lat);
    setCurLon(lon);

    setIsShowMap(true);
  };

  const changedPosition = (lat, lon) => {
    console.log("-----------", lat, lon)
    rows.forEach(row => {
      if (row.id === selId) {
        const e = { target: { name: 'lat_lon', value: lat, lat: lat, lon: lon } };
        onChange(e, row);
      }
    });
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <ConfirmActionModal
        title="Löschen bestätigen"
        text="Möchtest du diesen Eintrag wirklich löschen"
        onConfirm={() => { onRemoveClick(); toggleConfirm(false); }}
        show={isShowConfirm}
        onClose={() => toggleConfirm(false)}
        confirmText="Löschen"
      />
      <MapModal
        isMarkerShown={true}
        curPos={{ lat: curLat, lon: curLon }}
        title="Position wählen"
        onConfirm={(lat, lon) => { changedPosition(lat, lon); setIsShowMap(false); }}
        show={isShowMap}
        onClose={() => setIsShowMap(false)}
        confirmText="Ok"
      />
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell rowSpan={2} align="left" />
                <TableCell rowSpan={2}>Name</TableCell>
                <TableCell rowSpan={2}>anmeldung</TableCell>
                <TableCell colSpan={2} align="center">Standort</TableCell>
                <TableCell rowSpan={2}>Mitglieder</TableCell>
                <TableCell colSpan={3} align="center">Pfeiferschule</TableCell>
                <TableCell rowSpan={2}>Stammclique</TableCell>
                <TableCell colSpan={3} align="center">Trommelschule</TableCell>
                <TableCell rowSpan={2}>Übungslokal</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Breitengrad</TableCell>
                <TableCell>Längengrad</TableCell>
                <TableCell>Anfänger</TableCell>
                <TableCell>Fortgeschrittene</TableCell>
                <TableCell>Junge Garde</TableCell>
                <TableCell>Anfänger</TableCell>
                <TableCell>Fortgeschrittene</TableCell>
                <TableCell>Junge Garde</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(page * limit, (page + 1) * limit).map((row) => (
                <TableRow
                  hover
                  key={row.id}
                >
                  <TableCell className={classes.selectTableCell}>
                    {row.isEditMode ? (
                      <>
                        <IconButton
                          aria-label="done"
                          onClick={() => onSave(row.id, row)}
                        >
                          <DoneIcon />
                        </IconButton>
                        <IconButton
                          aria-label="revert"
                          onClick={() => onRevert(row.id)}
                        >
                          <RevertIcon />
                        </IconButton>
                      </>
                    ) : (
                        <>
                          <IconButton
                            aria-label="edit"
                            onClick={() => onToggleEditMode(row.id)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            onClick={() => { toggleConfirm(true); setDelId(row.id); }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </>
                      )}
                  </TableCell>
                  <CustomTableCell {...{ row, name: "name", onChange }} showGMap={(id, lat, lon) => showGMap(id, lat, lon)} />
                  <CustomTableCell {...{ row, name: "anmeldung", onChange }} showGMap={(id, lat, lon) => showGMap(id, lat, lon)} />
                  <CustomTableCell {...{ row, name: "lat", onChange }} showGMap={(id, lat, lon) => showGMap(id, lat, lon)} />
                  <CustomTableCell {...{ row, name: "lon", onChange }} showGMap={(id, lat, lon) => showGMap(id, lat, lon)} />
                  <CustomTableCell {...{ row, name: "mitglieder", onChange }} showGMap={(id, lat, lon) => showGMap(id, lat, lon)} />
                  <CustomTableCell {...{ row, name: "pfeiferschule_anfaenger", onChange }} showGMap={(id, lat, lon) => showGMap(id, lat, lon)} />
                  <CustomTableCell {...{ row, name: "pfeiferschule_fortgeschrittene", onChange }} showGMap={(id, lat, lon) => showGMap(id, lat, lon)} />
                  <CustomTableCell {...{ row, name: "pfeiferschule_jungegarde", onChange }} showGMap={(id, lat, lon) => showGMap(id, lat, lon)} />
                  <CustomTableCell {...{ row, name: "stammclique", onChange }} showGMap={(id, lat, lon) => showGMap(id, lat, lon)} />
                  <CustomTableCell {...{ row, name: "trommelschule_anfaenger", onChange }} showGMap={(id, lat, lon) => showGMap(id, lat, lon)} />
                  <CustomTableCell {...{ row, name: "trommelschule_fortgeschrittene", onChange }} showGMap={(id, lat, lon) => showGMap(id, lat, lon)} />
                  <CustomTableCell {...{ row, name: "trommelschule_jungegarde", onChange }} showGMap={(id, lat, lon) => showGMap(id, lat, lon)} />
                  <CustomTableCell {...{ row, name: "uebungslokal", onChange }} showGMap={(id, lat, lon) => showGMap(id, lat, lon)} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={rows.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25, 100]}
      />
    </Card>
  );
};

export default forwardRef(Results);
